@import './assets/scss/aiarchives-design-system.scss';
@font-face {
  font-family: 'Georgia';
  font-style: normal;
  font-weight: 400;
  src: local('Georgia'), local('Georgia'), url('./assets/fonts/Georgia.ttf') format('truetype'); /* Safari, Android, iOS */
}

body {
  background-color: #e6e6e5;
  margin: 0;
  font-family: 'Georgia', 'Georgia', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1 {
  font-family: Georgia;
  font-weight: bold;
  color: #2d2d2d;
  font-size: 40px;
}
h2 {
  font-family: Georgia;
  font-weight: bold;
  color: #2d2d2d;
  font-size: 20px;
}
.modal-content {
  border-radius: 20px;
  background-color: #f2f3f7;
  .modal-header {
    border-bottom: none;
  }
  .modal-body {
    .ui {
      margin-left: 10%;
      margin-bottom: 20px;
      width: 80%;
      height: 50px;
      border-radius: 10px;
      border: none;
      background: #f2f3f7;
      box-shadow: 10px 7px 20px #dde4ef, -10px -7px 20px #ffffff;
      &.selection.dropdown:focus {
        box-shadow: 10px 7px 20px #dde4ef, -10px -7px 20px #ffffff;
      }
      .visible {
        border: none;
        background: #f2f3f7;
        box-shadow: 10px 7px 20px #dde4ef, -10px -7px 20px #ffffff;
      }
      .dropdown.icon {
        top: 15px;
      }
      .text {
        margin-top: 6px;
      }
    }
    form {
      .text-muted {
        color: #2d2d2d;
      }
      h2 {
        width: 80%;
        margin: auto;
      }
      input {
        box-shadow: 10px 7px 20px #dde4ef, -10px -7px 20px #ffffff;
        background-color: #f2f3f7;
        border-radius: 10px;
        height: calc(1.5em + 1.4rem + 2px);
      }
      .form-group {
        margin-left: auto;
        margin-right: auto;
        width: 80%;
        .required {
          position: relative;
          right: 13px;
          top: 3px;
          color: red;
        }
      }
      .btn.btn-primary {
        font-size: 25px;
        font-family: Georgia;
        margin-top: 20px;
        margin-left: 10%;
        margin-bottom: 30px;
        border-radius: 10px;
        width: 80%;
        height: 50px;
        background-color: #e6e6e5;
        border-color: #2d2d2d;
        &:disabled {
          opacity: 0.5;
        }
      }
      .upload {
        padding: 8px 10px;
        position: relative;
        width: 140px;
        font-size: 1.2rem;
        color: white;
        span {
          font-size: 1.4rem;
          padding-left: 5px;
        }
        i.fas.fa-paperclip {
          padding-right: 3px;
        }
      }
      .upload input[type='file'] {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
      }
      .opacity {
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
        filter: alpha(opacity=0);
        -moz-opacity: 0;
        -khtml-opacity: 0;
        opacity: 0;
      }
      /*bootstrap*/
      .well {
        margin-left: 10%;
        color: white;
        background-color: #2d2d2d;
        width: 140px;
        border: 1px solid #2d2d2d;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
        -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
      }
    }
  }
}
