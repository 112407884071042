.glow {
  animation: glow 0.005s ease-in-out infinite alternate;
}

@keyframes glow {
  0% {
    box-shadow: 0 0 6px #cc0000, 0 0 12px #cc0000, 0 0 18px #cc0000, 0 0 24px #cc0000;
  }
  100% {
    box-shadow: 0 0 10px #cc0000, 0 0 20px #cc0000, 0 0 30px #cc0000, 0 0 40px #cc0000;
  }
}

.home-header-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  border-radius: 5px;
  list-style-type: none;
  padding-left: 0;
}

.home-header-dropdown-item {
  list-style: none;
  transition: background-color 0.3s ease;
  border-radius: 6px; /* round corners on li element */
}

.home-header-dropdown-link {
  display: block;
  width: 100%;
  padding: 3px 5px; /* Decrease top and bottom padding to reduce height */
  transition: background-color 0.3s ease, color 0.3s ease;
  text-decoration: none; /* Preserve text decoration */
}

.home-header-dropdown-item:hover {
  background-color: #A42C2C; /* Changed hover color to #A42C2C */
}

.home-header-dropdown-link:hover {
  color: #000;
}
