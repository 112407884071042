.Home {
  .header-img {
    width: 120px;
  }
  .header {
    .header-img {
      width: 160px;
      margin: 25px 0px 0px;
    }
    Button,
    a {
      margin-right: 20px;
      line-height: 25px;
      border-width: 2px;
      font-size: 1rem;
      width: 120px;
      height: 45px;
      margin: 32px 20px 0px;
      padding-top: 8px;
      padding-bottom: 8px;
      background-color: #f2f3f7;
      border-color: #2d2d2d !important;
      color: #2d2d2d;
      font-weight: 700;
      border-radius: 3px;
      &:hover {
        color: #020202;
        text-decoration: none;
      }
    }
  }
  .header.shadow {
    -webkit-box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
    -moz-box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
    box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
  }
  .submitBtn {
    Button,
    a {
      border-width: 2px;
      font-size: 1.2rem;
      height: 45px;
      background-color: #ffffff;
      border-color: #2d2d2d !important;
      color: #2d2d2d;
      font-weight: 700;
      border-radius: 3px;
      &:hover {
        color: #020202;
        text-decoration: none;
      }
    }
  }

  .steps {
    padding-bottom: 30px;
    .step-box {
      border-radius: 3px;
      background-color: #f0f0ef;
      box-shadow: rgba(0, 0, 0, 0.05) 0px 20px 24px 13px;
      transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12), 0.3s box-shadow,
        0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
      margin: auto;
      h1 {
        padding: 20px 20px 1px;
        font-weight: 500;
        font-size: 20px;
      }
      p {
        padding: 25px 30px 20px;
        font-size: 1.3rem;
      }
    }
    .step-box:hover {
      transform: scale(1.05);
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
    }
  }
  .step-headline {
    margin-bottom: 30px;
    margin-top: 30px;
  }
  .container {
    .content {
      margin-top: 120px;
      .hook-img {
        width: 100%;
      }
      .submit-box {
        border-radius: 3px;
        margin: auto;
        background-color: #f0f0ef;
        .whitespace-pre-wrap {
          margin: 0px 5px;
        }
        div.whitespace-pre-wrap {
          margin: 15px;
          font-size: 1.3rem;
        }
        h1 {
          margin: 30px;
        }
        p {
          margin: 15px;
          font-size: 1.3rem;
        }
        .conversation-box {
          p {
            margin: 10px 15px !important;
          }
        }
        Button {
          border-radius: 3px;
        }
        Button.image-button {
          //show gemini images
          display: block !important;
          border: none;
          img {
            height: 100% !important;
          }
        }
        .search {
          margin: 50px;
          padding-bottom: 50px;
          width: auto;
        }
      }
      .ui {
        margin-left: 40px;
        margin-right: 40px;
        margin-top: 20px;
        margin-bottom: 20px;
        width: 80%;
        height: 50px;
        border-radius: 3px;
        border: none;
        background: #f2f3f7;
        box-shadow: 10px 7px 20px #dde4ef, -10px -7px 20px #ffffff;
        &.selection.dropdown:focus {
          box-shadow: 10px 7px 20px #dde4ef, -10px -7px 20px #ffffff;
        }
        .visible {
          border: none;
          background: #f2f3f7;
          box-shadow: 10px 7px 20px #dde4ef, -10px -7px 20px #ffffff;
        }
        .dropdown.icon {
          top: 15px;
        }
        .text {
          margin-top: 6px;
        }
      }
      .btn.btn-primary {
        font-size: 25px;
        font-family: Georgia;
        margin-left: 40px;
        margin-right: 40px;
        margin-top: 20px;
        margin-bottom: 40px;
        width: 80%;
        height: 50px;
        background-color: #2d2d2d;
        border-color: #2d2d2d;
        &:disabled {
          opacity: 0.5;
        }
      }
    }
    .footer {
      padding-bottom: 100px;
      hr {
        width: 100%;
        margin-top: 80px;
        margin-bottom: 20px;
      }
      .logos {
        margin-left: auto;
        margin-right: auto;
        align-items: center;
        .companies {
          width: 100%;
          margin-top: 30px;
        }
      }

      .headline {
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        margin-bottom: 20px;
      }
    }
  }
}
.print-submit-box {
  display: none !important;
}
@media (max-width: 767px) {
  .Home {
    .header-img {
      width: 90px;
      margin: 7px 0px 0px;
    }
    .header {
      .header-img {
        width: 90px;
        margin: 7px 0px 0px;
      }
      Button,
      a {
        padding: 0px;
        margin-right: 0px;
        line-height: 12px;
        border-width: 1px;
        font-size: 0.7rem;
        margin: 12px 10px 0px;
        width: 90px;
        height: 30px;
        font-weight: 600;
        padding: 8px;
      }
    }
    .content {
      margin-top: 60px !important;
      .submit-box {
        h1 {
          font-size: 2.2rem;
        }
      }
    }
    .desktop {
      display: none;
    }
    .footer {
      h1 {
        font-size: 2.2rem;
        padding: 0px 10px;
      }
      .col-4 {
        &:nth-last-child(1) {
          margin-right: auto !important;
          margin-left: 0px !important;
        }
        &:nth-last-child(2) {
          margin-left: auto !important;
          margin-right: 0px !important;
        }
      }
    }
  }
}
.stats {
  display: flex;
  gap: 10px;
  margin-top: 5px;
}

.views,
.date-created {
  font-size: 14px;
}
.Login {
  position: absolute;
  top: 30%;
  left: 50%;
  min-width: 300px;
  margin-top: -100px;
  margin-left: -150px;
  .btn.btn-primary {
    font-size: 25px;
    font-family: Georgia;
    height: 50px;
    padding-bottom: 3px;
    padding-top: 3px;
    background-color: #ffffff;
    border-color: #2d2d2d;
    &:disabled {
      opacity: 0.5;
    }
  }
  .form-label {
    font-size: 1.2rem;
  }
  form {
    .text-muted {
      color: #2d2d2d;
    }
    h2 {
      width: 80%;
      margin: auto;
    }
    input {
      box-shadow: 10px 7px 20px #dde4ef, -10px -7px 20px #ffffff;
      background-color: #f2f3f7;
      border-radius: 3px;
      height: calc(1.5em + 1.4rem + 2px);
    }
    .form-group {
      .required {
        position: relative;
        right: 13px;
        top: 3px;
        color: red;
      }
    }
  }
}
@media all and (min-width: 480px) {
  .Login form {
    margin: 0 auto;
    max-width: 320px;
  }
  .submit-box {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 2px 24px 13px;
  }
}

@media (max-width: 480px) {
  .submit-box {
    padding: 0px;
    background-color: #e6e6e5 !important;
    p {
      margin: 10px 0px !important;
      font-size: 1.2rem !important;
    }
    .search {
      margin: 15px !important;
      padding-bottom: 30px !important;
    }
  }
}

@media print {
  .Home {
    display: none !important;
  }

  .print-submit-box {
    display: block !important;
  }

  .hide-on-print {
    display: none !important;
    margin-bottom: 100px;
  }
}

/*Claude */
.text-\[11px\] {
  font-size: 11px !important;
  margin: 3px 10px !important;
}
.text-stone-300 {
  color: #a9b2bf;
}
pre {
  p {
    margin: 4px 0px 0px 0px !important;
  }
}
.py-1\.5 {
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}
.bg-bg-400 {
  background-color: #21201c;
}
.rounded-lg {
  border-radius: 0.5rem;
}
/* Base styles for code container */
.flex {
  display: flex;
}
.bg-stone-900 {
  background-color: #23272a;
}
/* Hide the "Copy code" text */
.contents .text-stone-300 {
  display: none;
}

ol,
ul {
  list-style-type: disc;
  font-size: 1.2rem;
  line-height: 1.3 !important;
}

code {
  line-height: 1.5 !important;
  margin-bottom: 0px;
}

.code-block {
  background-color: #e8e5d8 !important;
  border-radius: 0.5rem;
  p {
    margin: 5px !important;
  }
}

.btn-models {
  width: 120px;
}

/* Media query for screens narrower than 600px */
@media (max-width: 600px) {
  .btn-models {
    width: 60px;
    font-size: 0.7rem !important;
  }
}

@keyframes blinkToRed {
  0% {
    background-color: #ffffff; /* Initial background color (white) */
    color: #000000; /* Initial text color (black) */
  }
  25% {
    background-color: #ff0000; /* Blink to red */
    color: #ffffff; /* Text changes to white for contrast */
  }
  50% {
    background-color: #ffffff; /* Back to initial background color */
    color: #000000; /* Back to initial text color */
  }
  75% {
    background-color: #ff0000; /* Blink to red again */
    color: #ffffff;
  }
  100% {
    background-color: #ffffff; /* Final state */
    color: #000000;
  }
}

.blink {
  animation: blinkToRed 1s ease-in-out 4; /* 4 cycles of the animation */
}
